import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import styled from 'styled-components'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import ServicesScrollSec from 'components/ServicesScrollSec/ServicesScrollSec'
import { images } from 'config/images'

import FeatureResources from 'components/FeatureResources/FeatureResources'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import DevlopmentApproach from 'components/DevlopmentApproach/DevlopmentApproach'
import CustomerReviewSlider from 'components/CustomerReviewSlider/CustomerReviewSlider'
import Aboutprojectform from 'components/Aboutprojectform/Aboutprojectform'
import AwardsRecognition from 'components/AwardsRecognition/AwardsRecognition'
import FaqSection from 'components/FaqSection/FaqSection'
import FromOurExpertsSec from 'components/FromOurExpertsSec/FromOurExpertsSec'
import { CloudDevServiceProps } from 'components/Props/cloud-development-service'
import TechPartnersSec from 'components/TechPartnersSec/TechPartnersSec'
import SimformGuaranteeSec from 'components/SimformGuaranteeSec/SimformGuaranteeSec'

export const CloudDevelopmentPage = styled.div`
  .banner {
    .banner-content {
      max-width: 1110px;
      p {
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
    .banner-img {
      display: block;
    }
  }
  .buildapp-section {
    margin-bottom: 0px;
  }
  .feature-resource-section {
    background: linear-gradient(to top, #eef2ff, #ffffff);
    .contact-info-card {
      display: none;
    }
  }
`

const SDSH = memo(props => {
  const { data } = props
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
    props.data.process4.childImageSharp.fluid,
    props.data.process5.childImageSharp.fluid,
    props.data.process6.childImageSharp.fluid,
  ]
  let imageItems = [
    props.data.casestudiesCardOne.childImageSharp.fluid,
    props.data.casestudiesCardTwo.childImageSharp.fluid,
    props.data.ebookCBHPWebApp.childImageSharp.fluid,
    props.data.brochureCard.childImageSharp.fluid,
    props.data.blogCardTwo.childImageSharp.fluid,
    props.data.blogCardThree.childImageSharp.fluid,
  ]
  let Partnerslogos = [
    props.data.Partnerslogo1.childImageSharp.fluid,
    props.data.Partnerslogo2.childImageSharp.fluid,
    props.data.Partnerslogo3.childImageSharp.fluid,
    props.data.Partnerslogo4.childImageSharp.fluid,
    props.data.Partnerslogo5.childImageSharp.fluid,
    props.data.Partnerslogo6.childImageSharp.fluid,
  ]
  let partnersLeftlogo = [props.data.AwsPartnerLogo1.childImageSharp.fluid]
  return (
    <Layout
      mainClass="software-dev-services services-page"
      location={location}
      data={{
        img: images.contactHs,
        img2x: images.contactHs2x,
        name: 'Hardik Shah',
        description:
          'Creating a tech product roadmap and building scalable apps for your organization.',
      }}
    >
      <SEO
        title="Cloud Development Services"
        description="Achieve exceptional scalability, accessibility, and data security with our cloud development services."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/services/cloud-development/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/services/cloud-development/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <CloudDevelopmentPage>
        <Banner
          bannerData={CloudDevServiceProps}
          showBannerImage={true}
          {...props}
        />
        <div className="lazy-load-div">
          <BuildApplication buildApplicationData={CloudDevServiceProps} />
        </div>
        {showContent && (
          <>
            <ServicesScrollSec
              serviceScrollSecdata={CloudDevServiceProps}
              {...props}
            />
            <TechPartnersSec
              TechPartnersSecData={CloudDevServiceProps.TechPartnersSecProps}
              {...props}
              partnersLeftlogo={partnersLeftlogo}
              Partnerslogo={Partnerslogos}
            />
            <ProcessFlow
              ProcessFlowData={CloudDevServiceProps.workingBenefitsProps}
              items={processFlowItem}
            />
            <DevlopmentApproach devlomentapproachdata={CloudDevServiceProps} />
            <FromOurExpertsSec
              FromOurExpertsSecData={CloudDevServiceProps.fromOurExpertsProps}
              ImageThumb={imageItems}
            />
            <CustomerReviewSlider
              customReviewSliderdata={CloudDevServiceProps}
              {...props}
            />
            <Aboutprojectform
              AboutprojectformData={CloudDevServiceProps.AboutprojectformProps}
            />
            <AwardsRecognition awardsRecognitiondata={CloudDevServiceProps} />
            <SimformGuaranteeSec
              SimformGuaranteeProps={CloudDevServiceProps.SimformGuaranteeData}
              posterImage={posterImage}
            />
            <FaqSection faqSectiondata={CloudDevServiceProps} />
            <FeatureResources
              featureresourceData={CloudDevServiceProps}
              {...props}
            />
          </>
        )}
      </CloudDevelopmentPage>
    </Layout>
  )
})

export default SDSH

export const query = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/cloud-development-service-banners@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/blog-cloud-deployment@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-react-native-perfo@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-mobile-app-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customSofBlog1: file(
      relativePath: { regex: "/blog-cost-optimization@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customSofBlog2: file(
      relativePath: { regex: "/blog-serverless-architecture@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    customSofBlog3: file(
      relativePath: { regex: "/blog-mongodb-mysql@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    SoftTestingBlog1: file(
      relativePath: { regex: "/blog-test-coverage@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    SoftTestingBlog2: file(
      relativePath: { regex: "/blog-functional-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    SoftTestingBlog3: file(
      relativePath: { regex: "/blog-mobile-app-testing@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    MobileAppBlog1: file(
      relativePath: { regex: "/blog-app-performance@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    MobileAppBlog2: file(relativePath: { regex: "/blog-app-debate@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    MobileAppBlog3: file(
      relativePath: { regex: "/blog-app-database@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webAppBlog1: file(relativePath: { regex: "/blog-build-scalable@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webAppBlog2: file(
      relativePath: { regex: "/blog-build-ecommerce@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    webAppBlog3: file(relativePath: { regex: "/blog-react-vue@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    DedicSoftBlog1: file(
      relativePath: { regex: "/blog-outsourcing-lessons@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    DedicSoftBlog2: file(
      relativePath: { regex: "/blog-ecommerce-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    DedicSoftBlog3: file(
      relativePath: { regex: "/blog-healthcare-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    softProdDevBlog1: file(
      relativePath: { regex: "/home-automation-using-iot-cover-image@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    softProdDevBlog2: file(
      relativePath: { regex: "/industrial-iot-iota-part.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    softProdDevBlog3: file(relativePath: { regex: "/blog-gps-tracking.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    apiIntegBlog1: file(relativePath: { regex: "/blog-reactnative@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    apiIntegBlog2: file(relativePath: { regex: "/blog-roundup2019@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    apiIntegBlog3: file(relativePath: { regex: "/blog-mvc-mvp@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appDevServiceBlog1: file(
      relativePath: { regex: "/blog-messaging-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appDevServiceBlog2: file(
      relativePath: { regex: "/blog-like-airbnb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    appDevServiceBlog3: file(relativePath: { regex: "/blog-mvc-mvp@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hireDedicDevBlog1: file(
      relativePath: { regex: "/blog-outsourcing-lessons@2x/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hireDedicDevBlog2: file(
      relativePath: { regex: "/blog-build-scalable@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hireDedicDevBlog3: file(
      relativePath: { regex: "/blog-ecommerce-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(
      relativePath: { regex: "/dbs-intrinsically-agiles@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/dbs-delivery-mindsets@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(relativePath: { regex: "/dbs-eminent-expertises@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 250) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process4: file(
      relativePath: { regex: "/cabnfs-smooth-migration@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process5: file(
      relativePath: { regex: "/cabnfs-proactive-planning@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process6: file(
      relativePath: { regex: "/cabnfs-multi-cloud-approach@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 230) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    olivia: file(relativePath: { regex: "/olivia.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    justin: file(relativePath: { regex: "/justin.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ritwik: file(relativePath: { regex: "/ritwik.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    danielle: file(relativePath: { regex: "/danielle.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jawann: file(relativePath: { regex: "/jawann.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jansen: file(relativePath: { regex: "/jansen.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jeff: file(relativePath: { regex: "/Jeff.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    andy: file(relativePath: { regex: "/andy.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    casestudiesCardOne: file(
      relativePath: { regex: "/foe-redbull-photobooth-cs-card@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    casestudiesCardTwo: file(
      relativePath: { regex: "/foe-ona-dating-cs-card@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ebookCBHPWebApp: file(
      relativePath: { regex: "/ebook-cloud-migration@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    brochureCard: file(
      relativePath: { regex: "/foe-brochure-cloud-native-dev@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    blogCardTwo: file(
      relativePath: { regex: "/foe-cloud-mg-strategy-blog@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    blogCardThree: file(
      relativePath: { regex: "/foe-cloud-pricing-comparison-blog@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    AwsPartnerLogo: file(
      relativePath: { regex: "/aws-partner-network-logo@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 480) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    AwsPartnerLogo1: file(
      relativePath: { regex: "/new-aws-cpartner-logo@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 480) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    Partnerslogo1: file(
      relativePath: { regex: "/aws-solutions-architect@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 150) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    Partnerslogo2: file(
      relativePath: { regex: "/aws-cloud-practitioner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 150) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    Partnerslogo3: file(
      relativePath: { regex: "/aws-devops-engineer@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 150) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    Partnerslogo4: file(
      relativePath: { regex: "/aws-security-specialty@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 150) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    Partnerslogo5: file(
      relativePath: { regex: "/aws-solutions-arct-associate@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 150) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    Partnerslogo6: file(
      relativePath: { regex: "/aws-sysops-admin-associat@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 150) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
