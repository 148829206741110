import React from 'react'
import { images } from 'config/images'
import { EXTERNAL_LINK } from 'config/utils'

export const CloudDevServiceProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Cloud Development <span className="heading-red">Services</span>
      </>
    ),
    bannerContent:
      'Maximize your business value by adopting end-to-end Cloud Development Services. With scalable computing resources at focus, we help you migrate resource-intensive processes, bringing better business agility and achieving the flexibility your business needs.',
    BannerAlt: 'Cloud Development Services',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Why Simform for{' '}
        <span className="highlight-head">Cloud Development Services</span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            Simform offers a full spectrum of cloud development services that
            help organizations migrate and refactor their workloads and
            applications for better and efficient use of the cloud. Our unique
            cloud management strategies and industrialized solutions help firms
            from different domains quickly build, test, and deploy cloud apps.
            <br /> <br />
            <strong>
              Proven experience in driving the paradigm shift towards the cloud.
            </strong>
            <br />
            <br />
            Simform’s cloud development services have helped organizations
            quickly innovate, improvise, and scale to meet the market’s changing
            demands. Our expert cloud architects and developers leverage the
            best technology to deliver excellence over a wide range of solutions
            such as,
            <br />
            <br />
          </>
        ),
      },
      {
        detailsList: [
          'Seasoned cloud consulting experts',
          'Hassle-free cloud migrations',
          'Secure cloud-native solutions',
          'Future-gen, scalable solutions',
          'Full-stack migration',
          'Agile integration strategies',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },

  serviceScrollSecProps: {
    servicesHeading: (
      <>
        Our Cloud Development <span className="highlight-head"> Services</span>
      </>
    ),
    servicesPara:
      'Deliver exceptional digital experiences without burning a hole in your pocket! Utilize our range of cloud development services that are designed to solve and seamlessly manage your cloud-related operations and deployment requirements.',
    sidebarHeading: 'Services',
    serviceListProps: [
      {
        heading: 'Cloud-native App Development',
        className: 'custom-software',
        mainPara:
          'Fast forward your business outcomes by leveraging Simform’s cloud native app development services. Our robust cloud engineering proficiency and variety of services helps your organization make the most of the cloud in all its forms.',
        listItem: [
          {
            title: 'App Modernization & Migration',
            para:
              'Modernize your cloud application and nullify any migration risks with Simform’s industry-leading cloud modernization and migration services. We help you leverage the power of cloud-native containers and microservices.',
          },
          {
            title: 'Strategy and Roadmap',
            para:
              'Our certified AWS developers help you create the strategy that your business needs for the next big thing. We help you create multi and poly cloud strategies for your cloud application.',
          },
          {
            title: 'Cloud-native App Development',
            para:
              'Simform’s expertise in terms of architecture patterns and best practices help you make the best use of cloud-native technologies to create, deploy, adopt and upgrade cloud-native applications.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/cloud-application-development/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Cloud Consulting',
        className: 'software-testing',
        mainPara:
          'Utilize our best-in-class cloud consulting services to give your business a competitive edge with expert cloud consultants. As a result, we help your business reduce extra costs, scale faster and increase the scalability of solutions.',
        listItem: [
          {
            title: 'Cloud Migration',
            para:
              'Get started with your cloud migration process right here with Simform. Reduce the infrastructure complexities by employing a smooth migration process with the help of certified container experts skilled in Kubernetes and Docker implementations.',
          },
          {
            title: 'Cloud Optimization',
            para:
              'Get expert guidance to optimize your cloud ecosystem over performance, storage, security, network, compute, and IT operations. Achieve higher efficiency by assigning the right resources to your workloads and applications.',
          },
          {
            title: 'Cloud Infrastructure Management',
            para:
              'Our cloud infrastructure management services help you leverage Simform’s industry-wide experience managing and monitoring cloud usage. We help businesses create, scale, configure and retire infrastructure as required.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/cloud-consulting/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Microservices Architecture',
        className: 'enterprise-mobile',
        mainPara:
          'Our end-to-end microservices architecture and deployment services help you remodel your application into independent and scalable microservices that allows you to offer a seamless user experience. Leverage the power of on-demand scalability.',
        listItem: [
          {
            title: 'Microservices Architecture Consulting',
            para:
              'Our microservices architecture consulting services help organizations like yours build a secure and scalable application architecture suitable to organization type, development team size, and functionality requirements.',
          },
          {
            title: 'Microservices Migration',
            para:
              'Leverage our product development experience to seamlessly migrate your application’s architecture to microservices. Devise a step-by-step implementation strategy for meticulous planning with Simform.',
          },
          {
            title: 'Microservices Testing',
            para:
              'Leverage the latest DevOps tools and best practices to streamline your microservices testing strategy. Deploy multiple services simultaneously by building a testing pipeline.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/microservices-architecture-development/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Data and Analytics Consulting',
        className: 'web-application',
        mainPara: (
          <>
            Unlock the potential of data with our range of data analytics
            services. Drive critical business insights that help you supercharge
            your organization's agenda. We help you model and collect user data
            that strengthen your decision-making process.
          </>
        ),
        listItem: [
          {
            title: 'Advanced Analytics Consulting',
            para:
              'Streamline and visualize your user data with the help of interactive dashboards. Extract relevant trends and achieve proactive data management with Simform’s advanced analytics consulting services.',
          },
          {
            title: 'Enterprise Data Management',
            para:
              'Apply standardized systems created for your specific use case to analyze large data sets, create accurate reporting, and make your enterprise data management task less complex.',
          },
          {
            title: 'Big Data and Business Intelligence',
            para:
              'Leverage Business Intelligence to convert your big data into actionable business insights. Reap full benefits of our state-of-the-art data services like data governance, data warehouse, and more.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/data-analytics-consulting/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Serverless application development',
        className: 'dedicated-software',
        mainPara:
          'Enjoy the automatic scalability and reduced operational costs with our serverless application development and consulting services. Forget about the backend provisioning or maintenance and focus solely on delivering an exceptional user experience to your customers.',
        listItem: [
          {
            title: 'Serverless Architecture Integration',
            para:
              'Simform’s AWS-certified cloud architects, create the serverless application architecture and integration roadmap for your application. We also help you optimize it with continuous monitoring and reporting. ',
          },
          {
            title: 'BaaS Development',
            para:
              'Leverage Backend-as-a-Service to create a robust foundation for your application. Automate your applications’ backend services with remote management of virtual machines and containers.',
          },
          {
            title: 'Serverless API Gateway',
            para:
              'Eliminate the use of unnecessary load balancers. Create intuitive Serverless APIs to work as communication layers between your Serverless logic and the rest of the application.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/serverless-app-development-consulting/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Kubernetes Consulting',
        className: 'product-development',
        mainPara: (
          <>
            Elevate your application’s performance by leveraging the
            best-in-class Kubernetes consulting services. We help you harness
            the power of Kubernetes' features like deployment patterns, canary
            deployment, failover monitoring system, etc.
          </>
        ),
        listItem: [
          {
            title: 'Managed Kubernetes Cloud',
            para:
              'Embrace the power of a flexible ecosystem and quick deployment. Make the most of Simform’s managed Kubernetes cloud services to build an end-to-end container cluster management system.',
          },
          {
            title: 'Kubernetes Consultation',
            para:
              'Efficiently orchestrate application containers with our Kubernetes consulting services. Set up fully-functional Kubernetes operations from setting up security protocols to application monitoring and automating deployments.',
          },
          {
            title: 'Infrastructure Health Audit',
            para: (
              <>
                Keep your application's critical areas in check with our
                infrastructure health audit services for Kubernetes. We help you
                audit your infrastructure and suggest changes for smoother
                application development.
              </>
            ),
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/kubernetes-consulting/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Cloud Migration Consulting',
        className: 'api-integration',
        mainPara: (
          <>
            Simform’s cloud migration services include evaluating the existing
            infrastructure and providing organizations the tools and
            methodologies required to migrate their complex and
            business-critical applications to the desired cloud infrastructure
            and architecture.
          </>
        ),
        listItem: [
          {
            title: 'Infrastructure Migration',
            para:
              'Simform’s cloud experts access your current infrastructure and present you with a detailed analysis. We understand your future requirements and create a strategic migration plan with the best tools and practices.',
          },
          {
            title: 'Platform Migration',
            para:
              'Whether you want to reduce the operational costs or gain higher development efficiency, Simform helps you migrate to your preferred cloud platform with zero risks of data loss.',
          },
          {
            title: 'Application Migration',
            para:
              'Migrate your legacy applications to the cloud with Simform. We help you refactor your existing applications and make them cloud-ready for a smoother migration and solid-proof execution.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/cloud-migration/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Cloud Architecture design and review',
        className: 'application-development',
        mainPara: (
          <>
            Leverage cloud capabilities like automation and orchestration with a
            resilient cloud platform to innovate safely, implement the latest
            cloud capabilities, and minimize system downtime with proven cloud
            architecture design services and tested strategies.
          </>
        ),
        listItem: [
          {
            title: 'Cloud Technology Strategy and Roadmap',
            para:
              'Upgrade your cloud infrastructure with Simform. Devise comprehensive cloud strategies and plan a roadmap that best suits your organization and helps you maximize the technology benefit.',
          },
          {
            title: 'Cloud Managed Services',
            para:
              'Make the most out of your cloud environment by bringing operational efficiencies to workflows. Efficient risk management, compliance with robust security, and matching workload needs tailored to your needs.',
          },
          {
            title: 'Cloud Deployment Services',
            para:
              'Integrate and manage multiple cloud environments from a single platform. Simform helps you plan, implement, and operate workloads on your behalf with a promise to reduce deployment times significantly.',
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/cloud-architecture-design/',
        type: EXTERNAL_LINK,
      },
      {
        heading: 'Cloud Assessment',
        className: 'hire-dedicated',
        mainPara: (
          <>
            Optimize the ROI of your cloud infrastructure with Simform. Our
            world-class cloud assessment and cost optimization services are
            designed to help organizations make the most of their cloud
            infrastructure.
          </>
        ),
        listItem: [
          {
            title: 'Cloud Cost Optimization',
            para:
              'Apply the best cost optimization strategies with Simform, and eliminate expenditure on your cloud infrastructure. Use data to eliminate potential caveats and save money usually spent on unused resources.',
          },
          {
            title: 'Cloud Compliance Assessment',
            para:
              'Make your cloud infrastructure compliant with the highest industry standards like HIPAA, PCI, GDPR, NIST, ISO, and more, with Simform’s cloud compliance assessment services.',
          },
          {
            title: 'Cloud DevOps Strategy and Implementation',
            para: (
              <>
                Enhance your system's performance and agility by utilizing our
                DevOps experts. Get your cloud infrastructure ready to meet the
                ever-changing business requirements.
              </>
            ),
          },
        ],
        serviceButtonText: 'Read More',
        ServiceLink: '/services/cloud-assessment-cost-optimization/',
        type: EXTERNAL_LINK,
      },
    ],
  },
  TechPartnersSecProps: {
    TechPartnersTitle: (
      <>Work with an official AWS Premier Consulting Partner</>
    ),
    TechPartnersInfo:
      'Simform, with its cloud partners, brings you the superpower of cloud-native applications. Our strategic alliances empower organizations like yours to thrive in the cloud.',
    partnersButtonText: 'Learn more',
    partnersButtonLink: '/amazon-web-services/',
    type: EXTERNAL_LINK,
  },
  fromOurExpertsProps: {
    FromOurExpertsTitle: (
      <>
        From <span className="highlight-head">Our Experts</span>
      </>
    ),
    FromOurExpertsContent: [
      {
        cardLink: '/case-studies/redbull-photobooth/',
        cardTitle: 'Redbull Photobooth',
        cardDetails:
          'Building a customizable and scalable photo booth technology for Redbull.',
        cardLinkText: 'Read Case Study',
      },
      {
        cardLink: '/case-studies/ona-dating/',
        cardTitle: 'ONA Dating',
        cardDetails:
          'Enhance your dating experience with an online ‘Matchmaker’ where users are coached, advised, and manually matched to genuine users.',
        cardLinkText: 'Learn More',
      },
      {
        linkType: EXTERNAL_LINK,
        cardLink: '/cloud-migration-ebook/',
        cardTitle: 'Comprehensive Guide To Simplify Cloud Migration',
        cardDetails:
          'Learn about the most common yet uncertain challenges that organizations often face when migrating to the cloud and learn how to overcome them.',
        cardLinkText: 'Download Ebook',
        HorizontalCard: true,
      },
      {
        linkType: EXTERNAL_LINK,
        openNewTab: true,
        cardLink:
          '/wp-content/uploads/2021/12/Cloud-native-development-services.pdf',
        cardTitle: 'Cloud Native Development Services',
        cardDetails:
          'Leverage modern cloud technologies and accelerate your product development.',
        cardLinkText: 'Download the Brochure',
        HorizontalCard: true,
      },
      // {
      //   linkType: EXTERNAL_LINK,
      //   cardLink: '/blog/cloud-migration-challenges/',
      //   cardTitle:
      //     '9 Cloud Migration Challenges and Solutions For CTOs in 2022',
      //   cardLinkText: 'Learn More',
      //   ThreeBlogCard: true,
      // },
      {
        linkType: EXTERNAL_LINK,
        cardLink: '/blog/cloud-migration-strategy/',
        cardTitle: 'Cloud Migration Strategy – The Ultimate Guide to the 6 R’s',
        cardLinkText: 'Learn More',
      },
      {
        linkType: EXTERNAL_LINK,
        cardLink: '/blog/compute-pricing-comparison-aws-azure-googlecloud/',
        cardTitle:
          'Cloud Pricing Comparison 2022: AWS vs Azure vs Google Cloud',
        cardLinkText: 'Learn More',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of working with
        <br /> <span className="highlight-head"> Simform</span>
      </>
    ),
    processPara: (
      <>
        Cloud development is more than just deploying applications and workloads
        to the cloud— it's about supercharging the software development cycle.
        At Simform, we've mastered modernizing legacy apps, building apps via
        the cloud (PaaS), creating cloud-native apps, and setting an environment
        that empowers development and operations teams for faster application
        builds and improved software quality.
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Intrinsically agile',
        workingBenefitsContent: (
          <>
            To keep up with the continuously evolving market trends and change
            customer expectations, Agile-backed DevOps is the way to move
            forward. We are extraordinarily agile so that you can pivot
            strategies at will to stay ahead of the competition.
          </>
        ),
        benefitImgAlt: 'Intrinsically agile',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Delivery mindset',
        workingBenefitsContent: (
          <>
            Everything we do, right from using modern tools to deploying new
            DevOps practices, stems from your requirements and business goals.
            We bring an untiring commitment towards your objectives and use it
            as the metric for success.
          </>
        ),
        benefitImgAlt: 'Delivery mindset',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Eminent expertise',
        workingBenefitsContent: (
          <>
            We bring you unparalleled tech acumen with 120+ successful DevOps
            projects and 50+ skilled DevOps consultants & engineers.
            Consequently, you get high-quality digital products that resonate
            with end-user requirements and your business objectives.
          </>
        ),
        benefitImgAlt: 'Eminent expertise',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Smooth migrations',
        workingBenefitsContent: (
          <>
            We offer cloud migrations that offer higher uptime, low-cost
            operations, and scalability. Our cloud-first approach helps you
            navigate the complexities, select the suitable cloud strategy and
            provide our technology expert guidance for a seamless and smoother
            migration experience.
          </>
        ),
        benefitImgAlt: 'Smooth migrations',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Proactive planning',
        workingBenefitsContent: (
          <>
            By laying down the business objectives and understanding what suits
            the best to your business needs, we define and establish cloud
            strategies, prioritize workloads and assess current infrastructure
            for better placements of goals.
          </>
        ),
        benefitImgAlt: 'Proactive planning',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Multi-cloud approach',
        workingBenefitsContent: (
          <>
            Multi-cloud strategy helps you reduce disasters, avoid disruptions,
            and enhance the availability of applications.
          </>
        ),
        benefitImgAlt: 'Multi-cloud approach',
      },
    ],
  },

  devApproachProps: {
    heading: (
      <>
        Our <span className="highlight-head">Cloud Development</span> Approach
      </>
    ),
    approachContents: [
      {
        approachBlkClass: 'perrywinkle',
        approachHeading: 'Requirement Gathering',
        approachContent: (
          <>
            The digital transformation journey of our clients begins with a
            discovery phase that involves cloud specialists analyzing current
            systems, business requirements, gauging the skill gap with your
            in-house teams and the technology prerequisites.
          </>
        ),
        approachImgSrc: images.resourcePlanning,
        approachImgAlt: 'Requirement Gathering',
      },
      {
        approachBlkClass: 'pale-orange',
        approachHeading: 'Infrastructure Audit and Design',
        approachContent: (
          <>
            Our team of senior-level architects, engineers, and project managers
            works with our in-house team to do a thorough cloud infrastructure
            assessment based on your business-specific requirements. Then, we
            offer a wireframe for the entire development process, including
            features and architecture design.
          </>
        ),
        approachImgSrc: images.IacinfrasManagement,
        approachImgAlt: 'Infrastructure Audit and Design',
      },
      {
        approachBlkClass: 'carnation',
        approachHeading: 'Development with Cutting-edge Technologies',
        approachContent: (
          <>
            Once the architecture and design are ready, our team will leverage
            cutting-edge cloud-based services to develop a reliable tech stack
            for your business. In addition, our modular approach throughout the
            development process helps organizations achieve higher availability
            and scalability.
          </>
        ),
        approachImgSrc: images.continuousDeployment,
        approachImgAlt: 'Development with Cutting-edge Technologies',
      },
      {
        approachBlkClass: 'robin-s-egg',
        approachHeading: 'Testing, Deployment, and Re-Architecture',
        approachContent: (
          <>
            The process of deployments is continuous, and with each iteration,
            our cloud architects and developers test your applications. Further,
            we re-architecture them for optimal performance according to the
            system’s behavior changes.
          </>
        ),
        approachImgSrc: images.continuousIntegration,
        approachImgAlt: 'Testing, Deployment, and Re-Architecture',
      },
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What <span className="highlight-head">Customers Say</span>
        <br />
        About Our Services
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },
  AboutprojectformProps: {
    title: (
      <>
        Tell Us About <span className="highlight-head">Your Project</span>
      </>
    ),
    formTitleL: 'Request a Free Quote',
    formInfo: 'Guaranteed response within one business day.',
    formDetailstitle: 'What will happen next?',
    formButtonText: 'LET"S GET STARTED',
    list: [
      'We’ll reach out to you within 24 hours.',
      'We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.',
      'You can start 15-days risk-free trial with us.',
    ],
  },
  awardsRecognitionProps: {
    heading: 'Awards & Recognition',
    awardBlockProps: [
      // {
      //   awardBlockImg: images.microsoftgold,
      // },
      {
        awardBlockImg: images.awsPartnerLogo,
      },
      {
        awardBlockImg: images.cmmiLogo,
      },
      {
        awardBlockImg: images.clutchGlobalLogo,
      },
      {
        awardBlockImg: images.theSteviesLogo,
      },
      {
        awardBlockImg: images.glassdoorfullLogo,
      },
      {
        awardBlockImg: images.GPTWLogo,
      },
      {
        awardBlockImg: images.atechchrunch,
      },
    ],
  },
  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  faqSectionProps: {
    heading: 'FAQs',
    faqProps: [
      {
        faqTitle:
          'What levels of support do you offer after developing a cloud app?',
        faqPara: (
          <>
            Our cloud development services are comprehensive and offer support
            at different levels throughout the development lifecycle. The first
            interaction of our team with you is for analyzing the business needs
            where we understand your vision and formulate a wireframe of the
            development process. After that, we support our clients at the
            planning, development, and deployment levels. <br />
            <br />
            Apart from these levels of support, we help our clients with
            licensing, generating secure policies, customization of tools, and
            maintenance of the applications. Our team works as an extension of
            your organization, takes care of the app’s performance, and ensures
            zero downtime.
          </>
        ),
      },
      {
        faqTitle: 'How to optimize cloud migration costs?',
        faqPara: (
          <>
            Due to the pay-per-use model of cloud computing, there are many ways
            to optimize costs. Starting with reducing unused instances, you can
            decrease the cost of computing resources. In addition, cloud
            providers like AWS offer the option of spot instances, reserved
            instances, and other such features that you can leverage to reduce
            the cost of computing resources.
            <br />
            <br />
            Data transfers are also a significant contributor to cloud costs
            which you can reduce through CDN (Content Delivery Network). Many
            cloud providers like AWS and Cloudflare offer CDNs for minimizing
            data transfer costs.
            <br />
            <br />
            Another way to optimize cloud costs is by ensuring the removal of
            unwanted files. Cloud architects can configure the rule of data
            deletion or migration between different types of storage through
            programming. For example, you can store active data in Azure Blob
            storage while programing data with frequent access errors stored in
            Azure Cold Blob storage.
            <br />
            <br />
            API calls are charged per object, and you can reduce the cost by
            using batch objects. In addition, it helps in decreasing API access
            costs. Another optimization that you can induce is by enabling
            auto-scaling of resources. With auto-scaling, you can save money on
            slow periods or days when there is lower traffic on your cloud apps.
            <br />
            <br />
            The cost of cloud-based applications depends on many different
            factors like deployment platforms, the volume of data traffic,
            infrastructure needs, and security measures. Any cloud application
            development cost will include costs like,
            <br />
            <br />
            <ul>
              <li>
                <strong>Storage costs-</strong> The cost incurred by the Storage
                Area Network(SAN) or Network Attached Store(NAS) management and
                infrastructure support.
              </li>
              <li>
                <strong>Network costs-</strong> With the cloud, your application
                needs a reliable network with lower latencies, and there are
                costs attached to it.
              </li>
              <li>
                <strong>Disaster recovery costs-</strong> Most cloud services
                have in-built disaster recovery systems, so the cost on this
                aspect is low.
              </li>
              <li>
                <strong>Datacenter costs-</strong> It includes all the costs
                associated with data center operations like electricity, floor
                space, cooling, and others.
              </li>
              <li>
                <strong>Platform costs-</strong> It includes annual operating
                costs and environment maintenance of the application on a
                platform.
              </li>
              <li>
                <strong>Software maintenance costs-</strong> It includes annual
                maintenance costs for software which involve licensing, OS
                upgrades, and security measures.
              </li>
            </ul>
            <br />
            As cloud services offer a pay-per-use model, developing and
            deploying applications depends on the platform type. We offer cloud
            application development services to optimize these costs to fit your
            budget.
          </>
        ),
      },
      {
        faqTitle: 'What kind of cloud security measurements do you follow?',
        faqPara: (
          <>
            We use best-in-class cloud security measures to ensure data
            protection and prevent attacks on your systems. Our team of cloud
            architects leverage IAM(Identity and Access Management) services
            from cloud providers and tweak them according to your business
            needs. Apart from the IAM, our cloud application development
            services also offer security measures like Threat Intelligence,
            Intrusion Detection Systems (IDS), and Intrusion Prevention Systems
            (IPS). <br />
            <br />
            Our team conducts extensive cloud vulnerability and penetration
            testing to ensure the protection of your core services. We also use
            micro-segmentations to bifurcate your workloads and migrate them
            with individual security policies.
          </>
        ),
      },
      {
        faqTitle: 'What are the cloud computing technologies you work with?',
        faqPara: (
          <>
            Cloud computing technologies include virtualization for hardware,
            operating systems, storage, and infrastructure. It enables
            organizations to offer a standard version of the cloud applications
            to all the clients for higher flexibility. Apart from
            virtualization, we employ other cloud computing technologies like
            utility models that offer pay-per-utilization facilities. <br />
            <br />
            There are three major cloud computing technologies in any utility
            model- IaaS, SaaS, and PaaS. Infrastructure as a Service offers an
            operating framework and application programming with VMs, bandwidth,
            monitoring services, etc. Platform as a Service simultaneously
            provides a development and deployment platform. Finally, software as
            a Service enables the transmission of cloud applications through web
            servers.
          </>
        ),
      },
    ],
    contactHeading: 'Have more questions?',
    contactPara:
      'Let us know and our experts will get in touch with you ASAP. ',
    supportImg: images.contactLadySm,
    buttonName: 'Talk to our experts',
    buttonLink: '/contact/',
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/cloud-deployment-models-vm-iaas-containers-serverless/',
        featurecardTitle:
          'How to Choose the Right Cloud Deployment Model for your Application?',
      },
      {
        blogLink: 'https://www.simform.com/blog/react-native-app-performance/',
        featurecardTitle:
          'React Native Performance: Major issues and insights on improving your app’s performance',
      },
      {
        blogLink: 'https://www.simform.com/mobile-app-testing/',
        featurecardTitle:
          'Mobile App Testing: Challenges, Types and Best Practices',
      },
    ],
  },
}
